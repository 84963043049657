import React from 'react'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
{/* <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry>
                   {
                    images.map((item, i)=>{
                        return <img src={item} alt={item} key={item}/>
                    })
                   }
                </Masonry>
            </ResponsiveMasonry> */}

const ImageGallery = ({images}) => {
  return (
    <div className="snapList">
        {
                    images.map((item, i)=>{
  return  <div className="spanItem"  key={item}>
        <img
            src={item}
            alt="sp-1"
            className="img-fluid"
        />
    </div>
    })
  }
</div>

  
    
  )
}

export default ImageGallery