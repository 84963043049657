import React,{useState} from 'react'

import Slider from "react-slick";
import '../assets/css/slider.css';
import parse from "html-react-parser";
function SwipeSlider({testimonials}) {

    const [slideIndex, setSlideIndex] = useState(0);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // mobileFirst:true,
        // autoplay:true,
        // autoplaySpeed:1000,
        beforeChange:(current, next)=>setSlideIndex(next),
        centerMode:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      return (
        <div className='testimonialSlider'>
  <Slider {...settings}>
            {
                testimonials.map((item, i)=>{
                    return  <div className={i === slideIndex ? 'testimonialSlide slide-active' : 'testimonialSlide'} key={item.id}>
                    <div className='testimonialCarouselItem'>
                        <div className='testimonialItemUser'>
                            <img src={item.profile_pic} alt='testimonial.png' />
                        </div>
                        <div className='testimonialItemContent'>
                            {/* <h5>Good Website</h5> */}
                            <div className="userWords" style={{marginBottom:'15px', marginTop:'15px'}}>
                               {parse(item.description)}
                               {item.description.length > 500 ? '...' : ''}
                             </div>
                            <p className='userName'>- {item.name}</p>
                            <p className='userDesignation'> {item.designation}</p>
                        </div>
                    </div>
                 </div>
                })
            }
         
         
        </Slider>
        </div>
      
        )
}

export default SwipeSlider