import api from '../../ApiConfig';
import { Apis } from '../../config';

const getFooterWidgets = async () => {
    try {
        let result = await api.get(Apis.GetFooterWidget);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const getHomeBannerWidgets = async () => {
    try {
        let result = await api.get(Apis.GetHomeBannerWidget);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 






const exportedObject = {
    getFooterWidgets,
    getHomeBannerWidgets
};
export default exportedObject;