import React from 'react'
import { Link } from 'react-router-dom'

const WhyAlzea = () => {
  return (
    <div className="innerPageWrapper">
    <div className="page-banner" style={{
      backgroundImage:`url('/images/whyChoose.png')`,
      backgroundRepeat:'no-repeat',
      backgroundSize:'cover',
      minHeight:'500px',
      display:'flex',
      alignItems:'center',
      padding:'0px'
      }}>
        <div className='patchContainer'>
          <div className='whyAlzContent'>
          <h1>WHY CHOOSE DESTINY CALLING?</h1>
           <p>Embark on a transformative journey with DESTINY CALLING, where over a thousand students
globally have found invaluable exposure and rewarding experiences. Our unwavering
commitment to excellence ensures the highest quality internships, placing you in renowned
properties and fine-dining establishments. What sets us apart is our personalized approach—we
tailor internships to align with your unique aspirations and career goals.</p>
<p>At DESTINY CALLING, empathy is at the core of our services. We delve into the challenges
faced by our interns and work permit holders abroad, analyzing each aspect comprehensively.
This empathetic understanding leads us to not just identify problems but to innovate solutions
for each one. Choosing DESTINY CALLING means choosing a partner that goes beyond
conventional placement services—your journey with us is characterized by personalization,
empathy, and solutions crafted for your success.</p>
          </div>
        </div>
    </div>
  
  <div className="page-content pt-50 pb-50">
    <div className='services'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4'>
              <div className='service-items'>
                   <img src='/images/icons/language-courses.png' alt=''/>
                    <h3>Communication Barriers?</h3>
                    <h4>LANGUAGE COURSES</h4>
                    <p>Master effective communication and seamlessly adapt to different cultures with Destiny Calling's tailored language courses. Elevate your language proficiency for a smooth journey abroad.</p>
                    <Link to="/why-choose-us/language-courses" className='borderButton'>Learn More</Link>
              </div>
          </div>
          <div className='col-lg-4'>
              <div className='service-items'>
                   <img src='/images/icons/fitingsideout.png' alt=''/>
                    <h3>Navigating International Health Challenges?</h3>
                    <h4>FITINSIDEOUT PROGRAM</h4>
                    <p>Our collaboration with FitInsideOut offers a holistic program, empowering students to overcome lifestyle challenges during their international stay. Enhance physical health, mental strength, and overall well-being for peak performance.</p>
                    <Link to="/why-choose-us/fit-insideout-program" className='borderButton'>Learn More</Link>
              </div>
          </div>
          <div className='col-lg-4'>
              <div className='service-items'>
                   <img src='/images/icons/therapy-support.png' alt=''/>
                   <h3>Battling Homesickness?</h3>
                    <h4>COUNSELING AND THERAPY SUPPORT</h4>
                    <p>We offer unwavering emotional support to our interns and working professionals, with dedicated mentors just a call away, ensuring every student feels understood. From talking to students in the middle of the night to ensuring the parents, we have always been there and, will be there for our students.</p>
                    <Link to="/why-choose-us/counseling-therapy-support" className='borderButton'>Learn More</Link>
              </div>
          </div>

        </div>
     </div>
    </div>
    <div className='our-goal'>
      <div className='container text-center'>
      <h4>Our Goal is Simple</h4>
        <p>To empower students and professionals by providing them with exceptional opportunities in France. We aim to inspire and support individuals in defining and achieving success on their own terms. By fostering connections with renowned establishments, we ensure that students receive high-quality experiences that enhance their personal and professional growth.</p>
      </div>
       
    </div>
   </div>
</div>
  )
} 

export default WhyAlzea