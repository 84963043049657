import React from 'react'
import { motion } from 'framer-motion'
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { MdWhatsapp } from "react-icons/md";

import parse from 'html-react-parser';
function Footer({footerWidgets}) {
  return (
    <footer>
    <div className='footerTop'>
        <p>Contact Us Today and Our Team Will Be Happy to Help!  </p>
        {/* <motion.a href={`#`} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.5 }}>+91 9561101102</motion.a> */}
        <a href={'https://wa.link/x1a7zp'} target='_blank' rel="noreferrer"><FaWhatsapp fontSize={25} color='#fff' />Get in Touch</a>
    </div>
    <div className='footerMiddle'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-12'>
                    <div className='footerWidget1'>
                        {parse(footerWidgets.widget_1)}
                        <ul className='footerSocials'>
                            {/* <li> <motion.a href="#" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.5 }}><FiFacebook size={18} /></motion.a></li>
                            <li> <motion.a href="#" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.5 }}><FiTwitter size={18} /></motion.a></li> */}
                            <li> <motion.a href="https://www.instagram.com/destinycalling.in" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.5 }} target='_blank'><FiInstagram size={18} /></motion.a></li>
                            <li> <motion.a href="https://www.linkedin.com/in/destiny-calling-466147159" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.5 }} target='_blank'><FiLinkedin size={18} /></motion.a></li>

                        </ul>
                    </div>
                </div>
                <div className='col-lg-3 col-md-12'>
                    <div className='footerWidget2'>
                    {parse(footerWidgets.widget_2)}
                    </div>
                </div>
                <div className='col-lg-5 col-md-12'>
                    <div className='footerWidget3'>
                      <iframe title="googleMap" src={footerWidgets.widget_3} width="100%" height="230" frameBorder="0" allowfullscreen={true}></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='footerBottom'>
        <div className='container'>
            <p>Copyright ©Destiny Calling 2022 - 2023 - Designed By <a href="/">Explosionweb Solutions</a></p>
        </div>
    </div>
    <div className='whatsApp'>
      <a href={'https://wa.link/lr7srq'} target='_blank' rel="noreferrer">
        <MdWhatsapp fontSize={32} color='#fff'/>
      </a>
    </div>
</footer>
  )
}

export default Footer