import React from 'react'
import { Link } from 'react-router-dom'

import { FiChevronsLeft } from 'react-icons/fi';
const PageNotFound = () => {
  return (
    <div className='error-page pt-15 pb-50'>
      <div className='container'>
        <div className='error-image-wrapper'>
        <img src='/images/404-error.png' alt='404'/>
          <Link to="/"><FiChevronsLeft fontSize={18} color='#fff'/> Back to Home</Link>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound