import React, { Fragment, useState } from 'react'
import { motion } from "framer-motion"
import '../../assets/css/mainSlider.css'
import { FaPlay } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import Modal from '../models/Modal';
import parse from 'html-react-parser';
const MainBanner = ({ homeBanner }) => {
    const [isOpen, setOpen] = useState(false);
    if (homeBanner === null) {
        return null;
    }
    return (
        <Fragment>
            <div className='mainBanner'>
                <div className='container-fluid'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <div className='bannerContent'>

                                {/* <h1 className='secondaryColor typewriter'>{bannerData[0].title}</h1>
                                <p>{bannerData[0].sub_title}</p> */}
                                {parse(homeBanner.banner_text)}
                                <motion.a
                                    href={homeBanner.read_more_link}
                                    className='primaryButton'
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.5 }}
                                >{homeBanner.read_more_label}</motion.a>
                            </div>
                        </div>
                        <div className='col-md-6 positionRelative'>
                            <div className='bnrBg'>
                                <img src={homeBanner.banner_video_thumb} alt='main' className='imageBanner' />
                                <button className='playControl' onClick={() => setOpen(true)}>
                                    <FaPlay size={50} color="rgba(255, 223, 233, 0.5)" />
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpen} closeModal={() => setOpen(false)} fullWidth={true}>
                <div className='modalHeader'>
                    <h1 className='pl-0 secondaryColor'>Advantages of learning French</h1>
                    <button onClick={() => setOpen(false)}><MdOutlineClose size={25} /></button>
                </div>

                {
                    parseInt(homeBanner.video_type) === 1 ? <div>{parse(homeBanner.banner_video_url)}</div> : <div>
                        <video width={`100%`} height={400} controls autoPlay>
                            <source src={homeBanner?.banner_video_url} type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                }

            </Modal>
        </Fragment>


    )
}
export default MainBanner