import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAllPost = async () => {
    try {
        let result = await api.get(Apis.GetAllPost);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const getSinglePost = async ($slug) => {
    try {
        let result = await api.get(Apis.GetSinglePost + $slug);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const getLatestPost = async () => {
    try {
        let result = await api.get(Apis.GetLatestPosts);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const exportedObject = {
    getAllPost,
    getSinglePost,
    getLatestPost
};
export default exportedObject;