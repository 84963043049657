import React, { Fragment, useState } from 'react'
import { FiPhoneCall, FiMail, FiMapPin } from "react-icons/fi";
import { Formik } from 'formik';
import { GetEnquires } from '../helpers';
import swal from 'sweetalert';
const ContactUs = () => {
    const [isLoading, setLoading] = useState(false);
    const handleSubmit = async(values, { setSubmitting, resetForm  })=>{
        setLoading(true);
         let resp = await GetEnquires.sendEnquiry(values);
         if(resp && resp.status){
            swal({
                title: "Good job!",
                text: resp.message,
                icon: "success",
                button: "OK",
              });
            // alert(JSON.stringify(resp, null, 2));
           
            setLoading(false);
            setSubmitting(false);
            resetForm();
         }
    }
    return (
        <Fragment>
            <div className='innerPageWrapper'>

                <div className='innerPageBanner'>
                    <div className='innerDesignEle'>
                        <svg width="491" height="196" viewBox="0 0 491 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 -49C30.9796 -4.1415 107.674 87.7727 214.613 96.5613C348.288 107.547 410.061 84.8889 485 192" stroke="url(#paint0_linear_58_3809)" stroke-opacity="0.8" stroke-width="13" />
                            <defs>
                                <linearGradient id="paint0_linear_58_3809" x1="6" y1="-49" x2="492.334" y2="260.517" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#286989" />
                    <stop offset="0.547917" stop-color="#34647a" />
                    <stop offset="1" stop-color="#88acbc" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className='container'>
                        <h1>Contact Us</h1>
                        <div className='bannerImg'>
                            <img src='/images/contactpagebnr.jpg' alt='about-banner' className='img-fluid' />
                        </div>
                    </div>
                </div>
                <div className='page-content pt-50 pb-50'>
                    <div className='container'>
                        <div className='contactBox'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='contactItem'>
                                        <div className='contactIcon'>
                                            <FiMail size={35} color={`#34647a`} />
                                        </div>
                                        <div className='contactEmails'>
                                            <p><a href='/'>priyanka.alzea@gmail.com</a></p>
                                            <p><a href='/'>priyanka@alzea.org</a></p>
                                        </div>

                                    </div>

                                    <div className='contactItem mb-0'>
                                        <div className='contactIcon'>
                                            <FiMapPin size={35} color={`#34647a`} />
                                        </div>
                                        <div className='contactEmails'>
                                            <p><a href='/'>Neelkanth Palms, Classique c,<br />
                                                901, behind Cinemax,<br />
                                                Ghodbunder road, Thane, Maharashtra - 400610 </a></p>

                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-6 positionRelative'>
                                    <div className='contactForm'>
                                        
                                        <Formik
                                            initialValues={{ name:'', email: '', phone: '' }}
                                            validate={values => {
                                                const errors = {};
                                                if (!values.name) {
                                                    errors.name = 'Name is required';
                                                }
                                               
                                                if (!values.email) {
                                                    errors.email = 'Email is required';
                                                } else if (
                                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                                ) {
                                                    errors.email = 'Invalid email address';
                                                }

                                                if (!values.phone) {
                                                    errors.phone = 'Phone is required';
                                                }else if(!/^[0-9]{10}$/i.test(values.phone)){
                                                    errors.phone = 'Invalid phone number';
                                                }
                                                return errors;
                                            }}
                                            onSubmit={handleSubmit}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting,
                                                /* and other goodies */
                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className='form-group'>
                                                        <label htmlFor='name'>Name</label>
                                                        <input type='text' 
                                                        id="name" 
                                                        className="form-control" 
                                                        name="name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                        />
                                                         {errors.name && touched.name && <span className='error-help'>{errors.name}</span>}
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='email'>Email</label>
                                                        <input type='text'
                                                        id="email"
                                                        className="form-control"
                                                        name="email"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        />
                                                         {errors.email && touched.email && <span className='error-help'>{errors.email}</span>}
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='phone'>Mobile Number</label>
                                                        <input type='text'
                                                        id="phone"
                                                        className="form-control"
                                                        name="phone"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.phone}
                                                        />
                                                         {errors.phone && touched.phone && <span className='error-help'>{errors.phone}</span>}
                                                    </div>
                                                    <div className='form-group'>
                                                        <button className='sbtContact' type="submit" disabled={isSubmitting}>
                                                            {
                                                                isLoading ? 'Please wait..' : 'Send Message'
                                                            }
                                                            
                                                            </button>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                        <div className='seperator'>Or</div>
                                        <div className='mobileShowCase'>
                                            <FiPhoneCall size={25} />
                                            <p>+91 9561101102</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='googleMap'>
                            <iframe title="myPlace" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.4317226612006!2d72.97346361494607!3d19.22000765242455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9435944b70f%3A0x38aab7dc4d532f80!2sNeelkanth+Palms%2C+Kapurbawdi%2C+Thane+West%2C+Thane%2C+Maharashtra+400607!5e0!3m2!1sen!2sin!4v1483014753850" width="100%" height="350" frameborder="0" allowfullscreen=""></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ContactUs