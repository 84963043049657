import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAllTestimonial = async () => {
    try {
        let result = await api.get(Apis.GetAllTestimonial);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const getLatestTestimonial = async () => {
    try {
        let result = await api.get(Apis.GetLatestTestimonial);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const getAllVideoTestimonial = async () => {
    try {
        let result = await api.get(Apis.GetAllVideoTestimonial);
        return result.data;
    } catch (error) {
         console.log(error);
        return null;
    }
}; 


const exportedObject = {
    getAllTestimonial,
    getLatestTestimonial,
    getAllVideoTestimonial
};
export default exportedObject;