import React,{Fragment, useEffect, useState} from 'react'
import { GetPosts } from '../../helpers';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns'
const Blog = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // const [page, setPage] = useState(1);

   const getData = async ()=>{
    const resp = await GetPosts.getAllPost();
    if(resp && resp.status){
      setData(resp.posts);
      setLoading(false);
    } 
    // setData((prev) => [...prev, ...resp]);
  
   }
   useEffect(()=>{
    getData();
   },[])
  //  useEffect(()=>{
  //   getData();
  //  },[page])

  //  const handelInfiniteScroll = async () => {
  //   try {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       console.log(data.length);
  //       if(data.length < 50){
  //           setLoading(true);
  //           setPage((prev) => prev + 1);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handelInfiniteScroll);
  //   return () => window.removeEventListener("scroll", handelInfiniteScroll);
  // }, []);

 
  return (
    <Fragment>

    <div className='innerPageWrapper'>

        <div className='innerPageBanner'>
           
            <div className='container'>
              
                <div className='bannerImg'>
                    <img src='/images/blogBnr.png' alt='about-banner' className='img-fluid' />
                </div>
            </div>
        </div>
        <div className='page-content pt-50 pb-50'>
            <div className='container'>
             
              {
                isLoading ? <div className="fakeLoader" ><div className="lod-1"><div className="lod-2"></div></div></div> :  <div className='row'>
                {
                    data.map((item, index)=>{
                        return   <div className='col-lg-4 col-md-4' key={index}>
                          <Link to={`/blog/${item.slug}`}>
                          <div className='blogItems'>
                            <div className='blogThumb'>
                                <img src={item.thumbnail} alt='blogThumb'/>
                             <div className='author_details'>
                              <h6>{item.auther_name}</h6>
                              <p>{ format (parseISO(item.post_date), 'd MMM Y') }</p>
                            </div>
                            </div>
                          
                            <div className='blogTitle'>
                                <h3>{item.title}</h3>
                            </div>
                        </div>
                          </Link>
                    </div>
                    })
                }
              

              </div>
              }

            </div>
        </div>
    </div>
</Fragment>
  )
}

export default Blog