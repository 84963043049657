import React, { useState, useEffect } from 'react'
import { FiSearch } from "react-icons/fi";
const SearchBar = ({handleSearch, setLoading}) => {
    const [searchTerm, setSearchTerm] = useState('')
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch(searchTerm)
            // Send Axios request here
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm, handleSearch]);

    const handleChangeKey = (value) =>{
        if(value.length >= 3){
           setLoading(true);
        }
        setSearchTerm(value)
    }

    return (
        <div className='searchbar'>
            <input type='text' placeholder='Search...'
             onChange={(e) => handleChangeKey(e.target.value)}/>
            <button><FiSearch size={22} color="#434343" /></button>
        </div>
    )
}

export default SearchBar