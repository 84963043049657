import React,{useState} from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import parse from "html-react-parser";
import { MdOutlineClose } from "react-icons/md";
import Modal from "../models/Modal";
const MeetOurTeam = ({ teamData }) => {
  const [isOpen, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  if (teamData.length === 0) {
    return null;
  }

  const handleItemClick = (item_id) =>{
    let data = teamData.find(el=>el.id === item_id);
    setModalData(data);
    setOpen(true);
  }
  return (
    <div className="meet-our-team team-2">
      <h3 class="title-3 text-center mb-4">Meet Our Team</h3>
      <div className="container">
        <div className="team-inner">
          <div className="row">
            {teamData.map((item, index) => {
              return (
                <div className="col-lg-4" key={item.id}>
                    <a onClick={() => handleItemClick(item.id)} >
                    <div className="team-item">
                    <div className="team-front">
                      <div className="team-item-inner">
                        <div className="team-thumb">
                          <img src={item.profile_pic} alt="team-img" />
                        </div>
                        <div className="team-about">
                          <div className="team-deg">
                            <h3>{item.name}</h3>
                            <p>{item.designation}</p>
                          </div>
                        </div>
                        <div className="team-socials">
                          <ul>
                            {item.facebook !== "" && (
                              <li>
                                <a href="/">
                                  <FiFacebook fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}

                            {item.twitter !== "" && (
                              <li>
                                <a href="/">
                                  <FiTwitter fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                            {item.instagram !== "" && (
                              <li>
                                <a href="/">
                                  <FiInstagram fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                            {item.linkedIn !== "" && (
                              <li>
                                <a href="/">
                                  <FiLinkedin fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="team-back">
                      <div className="team-item-inner">
                        <div className="team-thumb">
                          <img src={item.profile_pic} alt="team-img" />
                        </div>
                        <div className="team-about">
                          <div className="team-deg">
                            <h3>{item.name}</h3>
                            <p>{item.wanted_to_be}</p>
                          </div>
                          {/* {parse(item.about_member)} */}
                        </div>
                        <div className="team-socials">
                          <ul>
                            {item.facebook !== "" && (
                              <li>
                                <a href="/">
                                  <FiFacebook fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}

                            {item.twitter !== "" && (
                              <li>
                                <a href="/">
                                  <FiTwitter fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                            {item.instagram !== "" && (
                              <li>
                                <a href="/">
                                  <FiInstagram fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                            {item.linkedIn !== "" && (
                              <li>
                                <a href="/">
                                  <FiLinkedin fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                    </a>
                  
                 
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {
        modalData !== null &&   
        <Modal isOpen={isOpen} closeModal={() => setOpen(false)} maxWidth={800}>
        <div className="modalContent plr">
            <button className='md-btn-2' onClick={() => setOpen(false)}>
                <MdOutlineClose size={25} />
            </button>
            <div className='row align-items-center'>
                <div className='col-md-5'>
                    <div className="team-modal-img">
                      <img src={modalData.profile_pic} alt='img' className='pop-img' />
                    </div>
                </div>
                <div className='col-md-7'>
                <div className="team-modal-content">
                   <h3>{modalData.name}</h3>
                   <p>{modalData.designation}</p>
                   {parse(modalData.about_member)}
                </div>
                <div className="team-socials-2">
                          <ul>
                            {modalData.facebook !== "" && (
                              <li>
                                <a href="/">
                                  <FiFacebook fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}

                            {modalData.twitter !== "" && (
                              <li>
                                <a href="/">
                                  <FiTwitter fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                            {modalData.instagram !== "" && (
                              <li>
                                <a href="/">
                                  <FiInstagram fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                            {modalData.linkedIn !== "" && (
                              <li>
                                <a href="/">
                                  <FiLinkedin fontSize={16} color="#000" />
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                </div>
            </div>
           

        </div>
    </Modal>
     
      }
    
    </div>
  );
};

export default MeetOurTeam;
