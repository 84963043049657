import React, { Fragment, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FiChevronLeft, FiChevronRight, FiSend, FiPhoneCall  } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";
import Modal from '../components/models/Modal';
import { MdOutlineClose } from "react-icons/md";

const WildfitHealthProgram = () => {
    const [isOpen, setOpen] = useState(false);
    const [isOpenVideo, setOpenVideo] = useState(false);
    return (
        <Fragment>
            <div className='innerPageWrapper'>
                <div className='innerPageBanner'>
                    <div className='innerDesignEle'>
                        <svg width="491" height="196" viewBox="0 0 491 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 -49C30.9796 -4.1415 107.674 87.7727 214.613 96.5613C348.288 107.547 410.061 84.8889 485 192" stroke="url(#paint0_linear_58_3809)" stroke-opacity="0.8" stroke-width="13" />
                            <defs>
                                <linearGradient id="paint0_linear_58_3809" x1="6" y1="-49" x2="492.334" y2="260.517" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#D03D6B" />
                                    <stop offset="0.547917" stop-color="#B0519A" />
                                    <stop offset="1" stop-color="#5C65AE" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className='container'>
                        <h1>Wildfit Health Program</h1>
                        <div className='bannerImg'>
                            <img src='/images/french-language.jpg' alt='about-banner' className='img-fluid' />
                        </div>
                    </div>
                </div>
                <div className='page-content pt-15 pb-50'>
                    <div className='container'>
                        <div className='langPageContent'>

                            <h4 className='lang-title'>What is wildfit?</h4>
                            <p>Mauritius is a multiethnic society drawn from Indian, African, French & Chinese origin. It is a major tourist destination attracting more and more tourists from all over the world which in turn boosts the hotel management industry. Doing an internship here has a great scope to learn more about the hotel industry. Since the French cuisine and gastronomy is one of the important cuisines of Mauritius, French becomes an important language used in the hotel industry in Mauritius too. Hence it is an added advantage for the student to learn this language.</p>

                            <h4 className='lang-title'>Features</h4>
                            <ul className='features-list'>
                                <li>Mauritius is a multiethnic society drawn from Indian, African, French & Chinese origin. It is a major tourist destination attracting more and more tourists from all over the world which in turn boosts the hotel management industry.</li>
                                <li>Mauritius is a multiethnic society drawn from Indian, African, French & Chinese origin. It is a major tourist destination attracting more and more tourists </li>
                                <li>A multiethnic society drawn from Indian, African, French & Chinese origin. It is a major tourist destination attracting more and more tourists </li>
                                <li>A multiethnic society drawn from Indian, African, French & Chinese origin. It is a major tourist destination attracting more and more tourists </li>
                            </ul>

                            <div className='eligibility'>
                                <h4 className='lang-title'>Eligibility</h4>
                                <div className='eligibilityItems'>
                                    <ul>
                                        <li><a href='/'><img src='/images/our-program-icon/icon6.png' alt='icon' /><span>Internships In</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon1.png' alt='icon' /><span>Food Production</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon2.png' alt='icon' /><span>Housekeeping</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon3.png' alt='icon' /><span>Front Office</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon4.png' alt='icon' /><span>Sales & Marketing</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon5.png' alt='icon' /><span>Accounting</span></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className='take-challenge-box'>
                            
                                        <button onClick={() => setOpen(true)}> Take up the 90 day challenge</button>
                                
                            </div>
                           
                            <div className='meet-founder '>
                                <h4 className='lang-title'>Meet your Coach</h4>
                                <div className='meet-inner-box'>
                                <div className='row align-items-center '>
                                    <div className='col-lg-5 col-md-5 col-sm-5'>
                                        <div className='fd-img'>
                                            <img src='/images/coatch.png' alt='icon' />
                                        </div>

                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-7'>
                                        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolo doamet sint. Velit officia duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet consequat.</p>
                                        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolo doamet sint. Velit officia duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet consequat.</p>
                                        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolo doamet sint. Velit officia duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet consequat.</p>
                                        <div className='founder-details'>
                                            <h4>Joney James</h4>
                                            <p>UI/UX Designer</p>
                                        </div>
                                    </div>
                                </div>
                                </div>


                            </div>
                            <div className='user-testimonials'>
                                <h4 className='lang-title'>Testimonials</h4>

                                <div className='video-box'>
                                    <img src='/images/videoThumb2.png' alt='videoThumb'/>
                                    <button className='playControl' onClick={() => setOpenVideo(true)}>
                                    <FaPlay size={50} color="rgba(255, 223, 233, 0.5)" />
                                   </button>

                                </div>
                                <Carousel width={'100%'} infiniteLoop={true} showStatus={false} showIndicators={false} showArrows={true}
                                    renderArrowPrev={(clickHandler, hasPrev) => {
                                        return (
                                            <div
                                                className={`left-arrow`}
                                                onClick={clickHandler}
                                            >
                                                <FiChevronLeft className="w-9 h-9 text-white" fontSize={20} color='#000' />
                                            </div>
                                        );
                                    }}
                                    renderArrowNext={(clickHandler, hasNext) => {
                                        return (
                                            <div
                                                className={`right-arrow`}
                                                onClick={clickHandler}
                                            >
                                                <FiChevronRight className="w-9 h-9 text-white" fontSize={20} color='#000' />
                                            </div>
                                        );
                                    }}

                                >

                                    <div className={`testimonial-item`}>
                                        <div className='user-tm-thumb'>
                                            <img src='/images/testimonial-user.png' alt='ts' />
                                        </div>
                                        <div className='user-tm-details'>
                                            <div className='user-message'>
                                                <p>India, with its office in Thane, is a combined effort between India and France to assist students of both the countries to amalgamate culturally giving students more options for diversifying their international exposure. </p>
                                            </div>
                                            <div className='tm-user-details'>
                                                <h4>Joney James</h4>
                                                <p>UI/UX Designer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`testimonial-item`}>
                                        <div className='user-tm-thumb'>
                                            <img src='/images/testimonial-user.png' alt='ts' />
                                        </div>
                                        <div className='user-tm-details'>
                                            <div className='user-message'>
                                                <p>India, with its office in Thane, is a combined effort between India and France to assist students of both the countries to amalgamate culturally giving students more options for diversifying their international exposure. </p>
                                            </div>
                                            <div className='tm-user-details'>
                                                <h4>Joney James</h4>
                                                <p>UI/UX Designer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`testimonial-item`}>
                                        <div className='user-tm-thumb'>
                                            <img src='/images/testimonial-user.png' alt='ts' />
                                        </div>
                                        <div className='user-tm-details'>
                                            <div className='user-message'>
                                                <p>India, with its office in Thane, is a combined effort between India and France to assist students of both the countries to amalgamate culturally giving students more options for diversifying their international exposure. </p>
                                            </div>
                                            <div className='tm-user-details'>
                                                <h4>Joney James</h4>
                                                <p>UI/UX Designer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`testimonial-item`}>
                                        <div className='user-tm-thumb'>
                                            <img src='/images/testimonial-user.png' alt='ts' />
                                        </div>
                                        <div className='user-tm-details'>
                                            <div className='user-message'>
                                                <p>India, with its office in Thane, is a combined effort between India and France to assist students of both the countries to amalgamate culturally giving students more options for diversifying their international exposure. </p>
                                            </div>
                                            <div className='tm-user-details'>
                                                <h4>Joney James</h4>
                                                <p>UI/UX Designer</p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>



                        </div>
                    </div>

                </div>
            </div>
            <Modal isOpen={isOpen} closeModal={() => setOpen(false)} maxWidth={450}>
                <div className='modalHeader'>
                    <h1 className='pl-0 secondaryColor'> Take up the 90 day challenge</h1>
                    <button onClick={() => setOpen(false)}><MdOutlineClose size={25} /></button>
                </div>
                <div className='modalContent2 popup-form'>
                    <form className='form2'>
                        <div className='form-group'>
                            <label>Name</label>
                            <input type='text' className='form-control2' placeholder='XYZ' />
                        </div>
                        <div className='form-group'>
                            <label>Email</label>
                            <input type='email' className='form-control2' placeholder='xyz2233@gmail.com' />
                        </div>
                        <div className='form-group'>
                            <label>Phone</label>
                            <input type='tel' className='form-control2' placeholder='9999999999' />
                        </div>
                        <div className='form-group'>
                            <button className='primaryButton2 fullWidth'><FiSend fontSize={20} color={'#fff'} /> Send</button>
                        </div>
                    </form>
                    <div className='mobileShowCase'>
                        <FiPhoneCall size={25} color='#C62154'/>
                        <p>+91 9561101102</p>
                    </div>
                 
                </div>

            </Modal>

            <Modal isOpen={isOpenVideo} closeModal={() => setOpenVideo(false)} fullWidth={true}>
                <div className='modalHeader'>
                    <h1 className='pl-0 secondaryColor'>Advantages of learning French</h1>
                    <button onClick={() => setOpenVideo(false)}><MdOutlineClose size={25} /></button>
                </div>

                <video width={`100%`} height={400} controls autoPlay>
                    <source src="/videos/dummy.mp4" type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                    Your browser does not support the video tag.
                </video>
            </Modal>
        </Fragment>
    )
}

export default WildfitHealthProgram;