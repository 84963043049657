import React from 'react'
import '../../assets/css/modal.css'

const Modal = ({isOpen, closeModal, fullWidth=false, children, maxWidth = 'auto'}) => {
    if (!isOpen) return null;

    return (
        <div onClick={closeModal} className='modalWrapper'>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={`modalContainer ${fullWidth ? 'fullWidth' : ''}`} style={maxWidth === 'auto' ? {maxWidth :'auto'} : {maxWidth:`${maxWidth}px`}}>
               {children}
          </div>
        </div>
      );
}

export default Modal