import React, { Fragment } from "react";

const OurProgram = () => {
  return (
    <Fragment>
      <div className="innerPageWrapper">
        <div className="innerPageBanner-2">
          <img
            src="/images/about-bnr3.png"
            alt="about-banner"
            className="img-fluid"
          />
        </div>
        <div className="page-content pt-50 pb-50">
          <div className="container">
            <div className="our_program_sec_1">
              <h2>What we do?</h2>
              <h6>Your Passport to an Exciting Global Hospitality Career</h6>
              <p>
                At Destiny Calling, we're your one-stop guide for launching your
                hospitality career on the international stage. We don't just
                offer internships and work permit programs; we provide a gateway
                to endless possibilities in France, Mauritius, the Netherlands,
                Italy, the USA, Gulf countries, and beyond.
              </p>
              <p>
                But we don't stop there. As our tagline suggests, we go beyond
                the ordinary. We enhance your journey with value-added services
                like language training, health development assistance, therapy,
                and counseling. Our goal is to immerse you in vibrant cultures,
                help you explore a new world, foster independence, bolster your
                resume, learn from industry experts, and develop essential
                cross-cultural communication skills.
              </p>
              <p>
                With Destiny Calling, you can embark on a global adventure,
                strengthening your career while exploring the world.
              </p>
            </div>
            <div className="inner-page-section">
              <div className="row align-center">
                <div className="col-md-5">
                  <div className="inner-img-box">
                    <img src="/images/fanceThumb.png" alt="img" />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="inner-content-box">
                    <h2>France Internships</h2>
                    <h6>Embark on a French Adventure</h6>
                    <p>
                      France, the land of culinary excellence, beckons you to an
                      exceptional internship experience. Imagine working with
                      prestigious 4 & 5-star hotels, Michelin Star restaurants,
                      and fine dining gastronomic establishments. Here, you'll
                      elevate your skills and indulge in the world of culinary
                      artistry.{" "}
                    </p>
                    <p>
                      Our comprehensive package includes a monthly stipend, duty
                      meals, and accommodation, ensuring your comfort and
                      rewarding experience throughout your internship. Join us
                      in the heart of gastronomy and transform your career.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row align-center parent">
                <div className="col-md-7">
                  <div className="inner-content-box">
                    <h2>Mauritius Internships</h2>
                    <h6>Elevate your career in Mauritius</h6>
                    <p>
                      With a flawless and quick administrative process,
                      Mauritius is among our most loved programs. Principally
                      appreciated by the tourists for its natural beauty and
                      luxurious beach life, Mauritius possesses a growing
                      tourism sector and a need for skilled hotel management and
                      culinary students. This is your chance to rapidly advance
                      your career and transform your life. Mauritius is known
                      for its multi - ethnic diversity, and our program would
                      provide you with an opportunity to engulf yourself in its
                      rich cultural heritage.
                    </p>
                    <p>
                      Moreover, outstanding performance can lead to a work
                      permit, opening doors to exciting opportunities. Embrace
                      the paradise that is Mauritius and set your career on an
                      incredible trajectory.
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="inner-img-box">
                    <img src="/images/mauritius-internships-thumb.png" alt="img" />
                  </div>
                </div>
              </div>
              <div className="row align-center">
                <div className="col-md-5">
                  <div className="inner-img-box">
                    <img src="/images/netherlands.png" alt="img" />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="inner-content-box">
                    <h2>The Netherlands Work Permit</h2>
                    <h6>Work in the Gastronomic Haven of Europe</h6>
                    <p>
                    The Netherlands, a blend of chic sophistication and youthful innovation, invites you to
experience a unique work culture. If you're seeking a change unlike any other, this is the place
to be. Explore the European gastronomic scene while immersing yourself in a dynamic,
innovative atmosphere.
                    </p>
                    <p>
                    The Netherlands now has opened their door for all hotel management graduates, looking to
work soon after they have completed their studies, to get hands -on experience and refine their
skills. This work permit program offers a ticket to endless future work opportunities in Europe.
We take care of accommodation, meals, documentation, and more. All you need to do is pack
your bags and get ready to savor the world's best waffles.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row align-center parent">
                <div className="col-md-7">
                  <div className="inner-content-box">
                    <h2>Italy Work Permit</h2>
                    <h6>Brighten Your Career in Cultural Splendor</h6>
                    <p>
                    Italy, a cultural haven, offers a unique experience rooted in tradition. Apart from hotel
management positions, we currently have openings for drivers, fruit pickers, warehouse
packing, gardeners, farmers, etc. Italy probably is the only place, where food is not just
prepared with love but served and talked about with love and passion as well. Our package
includes accommodation, meals, documentation, and more. It's an adventure for all your
senses, from fashion to food, in a serene yet exciting landscape of opportunities.
                    </p>
                  
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="inner-img-box">
                    <img src="/images/italy.png" alt="img" />
                  </div>
                </div>
              </div>
              <div className="row align-center">
                <div className="col-md-5">
                  <div className="inner-img-box">
                    <img src="/images/usa.png" alt="img" />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="inner-content-box">
                    <h2>The USA</h2>
                    <h6>Sharpen your skills in the states</h6>
                    <p>
                    The USA, a hub of modernization and a melting pot of culture, an opportunity to work in such a
huge country, is a rare opportunity and we are happy to provide you with it. Accommodation,
documentation, mock interviews, guidance, meals, you ask for it and we have it included, a
program built keeping in mind the technicalities to which one needs to adhere while applying to
work in the states for a seamless experience. Explore a country known for its potential to
elevate careers and discover what makes the USA a land of endless possibilities.
                    </p>
                
                  </div>
                </div>
              </div>
              <div className="row align-center parent">
                <div className="col-md-7">
                  <div className="inner-content-box">
                    <h2>Gulf Countries</h2>
                    <h6>Learn, Grow and Explore in the Gulf</h6>
                    <p>
                    Step into a captivating blend of modernity and tradition, where the Gulf's unique culture
thrives, rich in heritage yet firmly rooted in its Asian origins. It's a luxury destination that
beckons everyone, and right now, it's undergoing a hospitality industry revolution.
Imagine working in a place that's in a constant state of evolution, dedicated to
self-improvement, and the very embodiment of luxury and uniqueness. The Gulf is not just a
destination; it's a promise of growth and enrichment.
                    </p>
                    <p>At Destiny Calling, we take care of your stay, meals, and documentation – they're our
responsibility. Your mission? To immerse yourself in an enriching learning experience and
explore the limitless possibilities that the Gulf has to offer.</p>
                  
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="inner-img-box">
                    <img src="/images/gulf-countries.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="inner-page-section">
              <h3 className="text-center">
              Destiny Calling's Simplified Seven-Step Internship Process :
              </h3>
              <ul className="step-ul">
                <li>
                  <div className="count">
                    <span>1</span>
                  </div>
                  <div className="step-content">
                    <h5 className="stitle">
                       Student Interview and Registration
                    </h5>
                    <p>
                    Once we receive your CV, our team will meticulously assess your skills and qualifications. If your
profile aligns with our requirements, we'll arrange a one-on-one interview with a dedicated
Destiny Calling team member. Depending on the interview, we will proceed with the registration
process.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="count">
                    <span>2</span>
                  </div>
                  <div className="step-content">
                    <h5 className="stitle">Internship Search</h5>
                    <p>
                    After you have completed the registration process, we will carefully build your CV, as it sets your
first impression and begin your internship search. We aim to find an internship which aligns
with your requirements and provides you with the desired exposure.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="count">
                    <span>3</span>
                  </div>
                  <div className="step-content">
                    <h5 className="stitle">Language Training (Optional)</h5>
                    <p>
                    As soon as you complete the registration process your language learning journey begins. While
we search for the perfect internship opportunity, you can access our online French portal,
designed to help you excel in French, a skill especially beneficial if you're aiming to work in
French-speaking or European countries. It offers an array of resources, including video lessons,
practice exercises, etc. and interactive live calls with us every week.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="count">
                    <span>4</span>
                  </div>
                  <div className="step-content">
                    <h5 className="stitle">Proposal, Interview and Acceptance</h5>
                    <p>
                    Upon finding the right internship opportunity, we shortlist the company and present your
candidature. If they see a strong fit, we arrange an interview to kick-start the next phase.</p>
<p>But we don't just stop there. We believe in thorough preparation. You're not alone in this
process. We provide extensive training and guidance, including mock interviews with our
experienced team members and essential interview materials. Our goal is to ensure you're
well-equipped and confident for the final interview.</p>
<p>Once you successfully clear the interview, we proceed to the next step – presenting you with
the proposal received. Your acceptance marks the green light, and we move forward swiftly
with the process, handling all the necessary documentation and logistics seamlessly
                    </p>
                  </div>
                </li>
                <li>
                  <div className="count">
                    <span>5</span>
                  </div>
                  <div className="step-content">
                    <h5 className="stitle">Documentation</h5>
                    <p>
                    We assist with all the paperwork, ensuring a smooth process from translation to verification.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="count">
                    <span>6</span>
                  </div>
                  <div className="step-content">
                    <h5 className="stitle">Visa Logistics</h5>
                    <p>
                    Once your documentation is in order, our visa expert will help you secure a visa appointment
and mentor you with all the administrative procedures.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="count">
                    <span>7</span>
                  </div>
                  <div className="step-content">
                    <h5 className="stitle">Flight, Route Map and Induction</h5>
                    <p>
                    Prior to your departure, you will gain access to informative resources, an individual induction -
if needed, that will provide you with essential information, tips, and guidance to ease your
departure and help you adapt better.
                    </p>
                  </div>
                </li>
              </ul>
              <div className="text-center">
                <p className="">
                Destiny Calling's seven-step process ensures you embark on your dream internship or work
opportunity with confidence.
                </p>
                <a className="primaryButton" href="/why-choose-us">
                WHY DESTINY CALLING?
                </a>
              </div>
            </div>
          </div>
          <div className="faq-action-box">
            <div className="container text-center">
              <h2>HAVE QUESTIONS?</h2>
              <p>
                SCROLL THROUGH OUR EFFECTIVE FAQ RESOURCE THAT WILL EDUCATE,
                INFORM AND ANSWER ALL YOUR QUERIES.
              </p>
              <a className="primaryButton" href="/faq">
                FAQ’s
              </a>
            </div>
          </div>
          <div className="program-form">
            <div className="container">
              <h5>
                BOOK A FREE DISCOVERY CALL WITH OUR INTERNSHIP ADVISOR TODAY
              </h5>
              <div className="program-form-wrapper">
                <form>
                  <div className="row form-group">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="XYZ"
                        id="name"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="918888888888"
                        id="phone"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="xyz2233@gmail.com"
                        id="email"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="primaryButton" type="submit">
                      Book Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OurProgram;
