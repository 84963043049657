import React, { Fragment, useEffect, useState } from 'react';
import { GetFaqs } from '../../helpers';
import SearchBar from '../../components/partials/SearchBar';
import FaqItems from './FaqItems';
const Faq = () => {
    const [isLoading, setLoading] = useState(true);
    const [faqs, setFaqs] = useState([]);
    const getData = async () => {
        let resp = await GetFaqs.getAllFaqs();
        if (resp && resp.status) {
            setFaqs(resp.faqs);
            setLoading(false);
        }
    }
    useEffect(() => {
        getData();
    }, [])

    const handleSearch = async (value) => {
        let searchKey = value.toLowerCase();
        if (searchKey.length >= 3) {
            let resp = await GetFaqs.searchFaq(searchKey);
            if (resp) {
                setFaqs(resp.faqs);
                setLoading(false);
            }
        } else {
            getData();
        }

    }
    return (
        <Fragment>
            <div className='innerPageWrapper'>

                <div className='innerPageBanner'>
                    <div className='innerDesignEle'>
                        <svg width="491" height="196" viewBox="0 0 491 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 -49C30.9796 -4.1415 107.674 87.7727 214.613 96.5613C348.288 107.547 410.061 84.8889 485 192" stroke="url(#paint0_linear_58_3809)" stroke-opacity="0.8" stroke-width="13" />
                            <defs>
                                <linearGradient id="paint0_linear_58_3809" x1="6" y1="-49" x2="492.334" y2="260.517" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#D03D6B" />
                                    <stop offset="0.547917" stop-color="#B0519A" />
                                    <stop offset="1" stop-color="#5C65AE" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className='container'>
                        <h1>FAQ</h1>
                        <div className='bannerImg'>
                            <img src='/images/faqbnr.jpg' alt='about-banner' className='img-fluid' />
                        </div>
                    </div>
                </div>
                <div className='page-content pb-80 pt-50'>
                    <div className='container'>
                        <SearchBar handleSearch={handleSearch} setLoading={setLoading} />
                        {
                            isLoading ? <div className='loading-2'>
                                <img src='/images/loading-2.gif' alt='loading-2' />
                            </div> : faqs.length !== 0 ? <FaqItems data={faqs} /> : <div className='data-not-found'><p>Data not found !</p></div>}


                        <div className='footerDesign'>
                            <svg width="438" height="249" viewBox="0 0 438 249" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 4C30.9796 48.8585 107.674 140.773 214.613 149.561C348.288 160.547 410.061 137.889 485 245" stroke="url(#paint0_linear_76_1264)" stroke-opacity="0.8" stroke-width="13" />
                                <defs>
                                    <linearGradient id="paint0_linear_76_1264" x1="6" y1="4" x2="492.334" y2="313.517" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#D03D6B" />
                                        <stop offset="0.547917" stop-color="#B0519A" />
                                        <stop offset="1" stop-color="#5C65AE" />
                                    </linearGradient>
                                </defs>
                            </svg>

                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    )
}

export default Faq