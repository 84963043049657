import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAllFaqs = async () => {
    try {
        let result = await api.get(Apis.GetAllFaq);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const getLatestFaqs = async () => {
    try {
        let result = await api.get(Apis.GetLatestFaq);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 

const searchFaq = async (searchKey) => {
    try {
        let result = await api.get(Apis.SearchFaq + searchKey);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 


const exportedObject = {
    getAllFaqs,
    getLatestFaqs,
    searchFaq
};
export default exportedObject;