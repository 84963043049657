import React, { Fragment } from "react";



const CounselingTherapySupoort = () => {
    return (
        <Fragment>
            <div className="innerPageWrapper">
                <div className="innerPageBanner-2">
                    <img
                        src="/images/program/counseling.png"
                        alt="about-banner"
                        className="img-fluid"
                    />
                </div>
                <div className="page-content pt-15">
                    <section className="inner-program-sec-1">
                        <div className="container">
                            <h4 className="lang-title ">
                                Cultivating emotional resilience - Counseling and Therapy Support
                            </h4>
                            <p>
                                At Destiny Calling, we recognize the emotional hurdles that arise when interns venture
                                far from their home turf. Our commitment is unwavering – providing steadfast
                                emotional support throughout this transformative journey. With just a phone call, our
                                dedicated mentors offer a compassionate ear and a reliable shoulder for our students,
                                creating a nurturing environment where everyone feels understood, valued, and
                                supported. </p>

                            <p>For our international interns and professionals, we've established a dedicated team
                                focused on staying connected and gathering timely updates. Understanding the unique
                                challenges of working in a foreign country, our international team is steadfast in
                                providing assistance whenever our students need it.</p>
                            <p>In addition to our internal support, we offer professional therapy services upon request
                                or when deemed necessary by our team. Our specially qualified therapists address
                                challenges such as homesickness and cultural shock at minimal costs, ensuring our
                                students have access to the emotional care they need.</p>

                        </div>
                    </section>
                    <section className="inner-program-sec-3 pt-0">
                        <div className="container">

                            <h4 className="lang-title text-center mb-25"> How shall it benefit you?  </h4>
                            <div className='services'>
                                <div className='container'>
                                    <div className='row justify-content-center'>
                                        <div className='col-lg-4 mb-25'>
                                            <div className='service-items item2'>
                                                <img src='/images/icons/emotional-well-being.png' alt='' />
                                                <h4>Emotional Well-being</h4>
                                                <p className="mb-0">Being away from home can often evoke feelings of homesickness,
loneliness, and emotional distress. Destiny Calling dedicated mentors offer a supportive and
understanding environment, allowing parents and children to express their emotions freely.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='service-items item2'>
                                                <img src='/images/icons/enhanced-performance2.png' alt='' />
                                                <h4>Enhanced Performance</h4>
                                                <p className="mb-0">Emotional support plays a crucial role in a student's success. When
students feel emotionally supported and understood, they can focus better on their internship,
resulting in improved concentration, motivation, and overall performance.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='service-items item2'>
                                                <img src='/images/icons/builds-stronger-professional.png' alt='' />
                                                <h4>Builds Stronger Professional Relationships</h4>
                                                <p className="mb-0">By having a dedicated support team in their
host country, students gain exposure to diverse cultures and perspectives, enhancing their
ability to connect with others on a deeper level. This increased empathy allows students to build
stronger professional relationships.</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bgPrimary quote-box">
                        <div className="inner-quote-box">
                            <p className="quote-text">"
I have witnessed firsthand how emotional support has made a significant difference in the lives
of our students. They can indeed focus on their internship, work hard and rise on the pinnacle of
success”</p>
                            <div className="quote-user">
                                <span> - Priyanka Agrawal</span>
                                <span>Founder, Destiy Calling</span>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </Fragment>
    );
};

export default CounselingTherapySupoort;
