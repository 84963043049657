import React, { Fragment, useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { motion } from "framer-motion"
import SwipeSlider from '../components/SwipeSlider';
import Accordions from '../components/Accordions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GetFaqs, GetTestimonial, GetPages, GetWidgets, GetPosts } from '../helpers';
import { format, parseISO } from 'date-fns'
//home components
import MainBanner from '../components/homeComponents/MainBanner';
import VideoTestimonial from '../components/homeComponents/VideoTestimonial';

import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import programs from '../data/program.json'
import portfolios from '../data/portfolios.json'
function Home() {
    // const [isLoading, setLoading] = useState(true);
    const [faqs, setFaqs] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [latestPosts, setLatestPosts] = useState([]);
    const [aboutContent, setAboutContent] = useState(null);
    const [homeBanner, setHomeBanner] = useState(null);

    const getData = async()=>{
        try {
        let [res1, res2, res3, res4, res5] = await Promise.all([
            GetFaqs.getLatestFaqs(),
            GetTestimonial.getLatestTestimonial(),
            GetPages.getAboutUs(),
            GetWidgets.getHomeBannerWidgets(),
            GetPosts.getLatestPost()
            
          ]);
          if(res1 && res1.status){
            setFaqs(res1.faqs)
          }
    
          if(res2 && res2.status){
            setTestimonials(res2.testimonials)
           }

           if(res3 && res3.status){
            setAboutContent(res3.aboutContent)
           }
           if(res4 && res4.status){
            setHomeBanner(res4.homeBanner)
           }
           if(res5 && res5.status){
            setLatestPosts(res5.posts)
           }
          
        }
        catch(err) {
          console.log(err);
        };
    }

    useEffect(()=>{
         getData();
    },[])


    return (
        <Fragment>
 
            <MainBanner homeBanner={homeBanner} />
            <div className='designElement'>
                    <div className='designEle'>
                        <svg width="478" height="188" viewBox="0 0 478 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-6 4C18.9796 37.3181 95.6736 105.586 202.613 112.114C336.288 120.274 398.061 103.444 473 183" stroke="url(#paint0_linear_58_2348)" strokeOpacity="0.8" strokeWidth="13" />
                            <defs>
                                <linearGradient id="paint0_linear_58_2348" x1="-6" y1="4" x2="388.021" y2="341.623" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#286989" />
                                    <stop offset="0.547917" stopColor="#34647a" />
                                    <stop offset="1" stopColor="#88acbc" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            <div className='ourLanguages'>
                <div className='container'>
                    <h1>BUILD AN INTERNATIONAL CAREER, ONE INTERNSHIP AT A TIME</h1>
                    {/* <div className='row'>
                        <div className='col-lg-6 mb-25'>
                            <div className='programText'>
                                <div className='program-text-header'>
                                <img src='/images/educational-programs.png' alt='program'/>
                                <h3>France 6 Months Program</h3>
                                </div>
                               
                                <p>Embark on a transformative culinary journey with Destiny Calling's France Program. Intern with prestigious hotels and Michelin Star restaurants, receiving a monthly stipend and accommodation. Join now to boost your career in Hotel Management and Culinary with France's gastronomic excellence. </p>
                                <Link className="borderButton style-2" to='/french-language'>Learn more</Link>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-25'>
                        <div className='programText'>
                        <div className='program-text-header'>
                                <img src='/images/educational-programs.png' alt='program'/>
                                <h3>MAURITIUS 6 MONTHS/1 YEAR PROGRAM</h3>
                                </div>
                           
                                <p> Destiny Calling presents an extraordinary opportunity for Hotel Management and Culinary students with our Mauritius Program. Mauritius offers an exceptional environment to propel your career with job placements in 4 & 5 star restaurants, a monthly stipend, and support from the ‘Ministère de l'Intérieur’. Kickstart your hotel industry career with Destiny Calling.</p>
                                <Link className="borderButton style-2" to='/french-language'>Learn more</Link>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-25'>
                        <div className='programText'>
                        <div className='program-text-header'>
                                <img src='/images/educational-programs.png' alt='program'/>
                                <h3>QATAR JOB PLACEMENT PROGRAM</h3>
                                </div>
                           
                                <p> Elevate your international career with Destiny Calling's Qatar Program. Explore exciting job placements in Housekeeping, Food & Beverage, Kitchen roles, and even Spa Therapists positions. We specialize in making your journey seamless, offering accommodation, meals, and flight inclusion. Join us to take your first step toward a successful career in the hospitality industry with Qatar's unique opportunities.</p>
                                <Link className="borderButton style-2" to='/french-language'>Learn more</Link>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-25'>
                        <div className='programText'>
                        <div className='program-text-header'>
                                <img src='/images/educational-programs.png' alt='program'/>
                                <h3>ITALY WORK PERMIT PROGRAM</h3>
                                </div>
                           
                                <p> Experience an Italian adventure with Destiny Calling's Italy Work Permit Program. Our
comprehensive program provides accommodation, duty meals, medical insurance, and a
competitive salary, ensuring your peace of mind while working abroad. We offer diverse job
vacancies, including kitchen assistants, housekeeping, F&B roles, warehouse packing,
drivers/concierges, gardeners, fruit pickers, and farm workers. Explore the richness of Italian
culture while gaining invaluable work experience. Plus, you can even apply for a residence
permit later. Take the first step toward a rewarding international career with Destiny Calling.</p>
                                <Link className="borderButton style-2" to='/french-language'>Learn more</Link>
                            </div>
                        </div>
                    </div> */}
                       <div className='row'>
                    {
                        programs.map((item)=>{
                            return <div className='col-lg-6 mmb-15' key={item.id}>
                            <div className='programText'>
                                <div className='program-text-header'>
                                <img src='/images/educational-programs.png' alt='program'/>
                                <h3>{item.title}</h3>
                                </div>
                               
                                <p>{item.description}</p>
                                <Link className="borderButton style-2" to={item.link}>Learn more</Link>
                            </div>
                        </div>
                        })
                    }
                        
               
                    </div>
                </div>
            </div>
          
            {
                aboutContent !== null &&   <div className='aboutSection'>
          
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-5 pfc'>
                            <motion.img
                                whileHover={{ scale: 1.1, }}
                                whileTap={{ scale: 1 }}
                                initial={{ scale: 1, transitionDelay: 2, }}
                                src={aboutContent.small_image} alt='about' className='img-fluid' />
                        </div>
                        <div className='col-md-7'>
                            <div className='aboutContent'>
                                <h1>{aboutContent.page_title}</h1>
                                {parse(aboutContent.short_description)}
                                <Link to={aboutContent.read_more_link} className='primaryButton'>{aboutContent.read_more_label}</Link>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
            }
            
          
            <div className='ourPortfolio'>
                <div className='container'>
                    <h1>Our portfolio of services include :</h1>
                    <div className='portfolioIcons '>
                        {
                            portfolios.map((item)=>{
                              return  <div className={`portfolioIconBox ${item.class}`}>
                              <div className='iconWrapper'>
                                  <img src={item.icon_img} alt="" />
                              </div>
                              <h5>{item.title}</h5>
                              <p>{item.description}</p>
                          </div>
  
                            })
                        }
                    </div>

                </div>


            </div>
            {/* <div className='ourLearnings'>
                <div className='container'>
                    <h1>Learning / Study Trips</h1>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='learningItems'>
                                <div className='learningItemsThumb'>
                                    <img src='/images/l1.jpg' alt='li.jpg' />
                                    <div className='blur'></div>
                                </div>
                                <div className='learningItemsContent'>
                                    <h3>4 days & 5 nights</h3>
                                    <div className='location'>
                                        <img src='/images/map-pin.png' alt='map-pin'/>
                                        <p>Lyon & Pays Beaujolais</p>
                                    </div>
                                    <p>Wine, gastronomy and
                                        cultural discoveries in Lyon
                                        & Pays Beaujolais (France)</p>
                                   
                                    <Link to={'#'} className='primaryButton'>Trip Details</Link>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='learningItems'>
                                <div className='learningItemsThumb'>
                                    <img src='/images/l2.jpg' alt='l2'/>
                                    <div className='blur'></div>
                                </div>
                                <div className='learningItemsContent'>
                                    <h3>4 days & 5 nights</h3>
                                    <div className='location'>
                                        <img src='/images/map-pin.png' alt='map'/>
                                        <p>Lyon & Pays Beaujolais</p>
                                    </div>
                                    <p>Wine, gastronomy and
                                        cultural discoveries in Lyon
                                        & Pays Beaujolais (France)</p>
                                  
                                     <Link to={'#'} className='primaryButton'>Trip Details</Link>


                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='learningItems'>
                                <div className='learningItemsThumb'>
                                    <img src='/images/l3.jpg' alt='l3'/>
                                    <div className='blur'></div>
                                </div>
                                <div className='learningItemsContent'>
                                    <h3>4 days & 5 nights</h3>
                                    <div className='location'>
                                        <img src='/images/map-pin.png' alt='map'/>
                                        <p>Lyon & Pays Beaujolais</p>
                                    </div>
                                    <p>Wine, gastronomy and
                                        cultural discoveries in Lyon
                                        & Pays Beaujolais (France)</p>
                                  
                                     <Link to={'#'} className='primaryButton'>Trip Details</Link>


                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='learningItems'>
                                <div className='learningItemsThumb'>
                                    <img src='/images/l4.jpg' alt='l4'/>
                                    <div className='blur'></div>
                                </div>
                                <div className='learningItemsContent'>
                                    <h3>4 days & 5 nights</h3>
                                    <div className='location'>
                                        <img src='/images/map-pin.png' alt='map'/>
                                        <p>Lyon & Pays Beaujolais</p>
                                    </div>
                                    <p>Wine, gastronomy and
                                        cultural discoveries in Lyon
                                        & Pays Beaujolais (France)</p>
                                   
                                     <Link to={'#'} className='primaryButton'>Trip Details</Link>


                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </div> */}
            {/* <div className='ourLanguages'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='languageText'>
                                <h3>French language</h3>
                                <p>Bonne Appetit! Hors d’œuvre, garde manger, gastronomie, pâtisserie entrée, à la carte. . . Sounds familiar?</p>
                                <p>You use these and many more French terms in your curriculum. Here’s a chance to learn more and learn it correctly!</p>
                                <p>French is the second most spoken language after English in the world. It is the most important language used in </p>
                                <p>The hotel management / gastronomy industry. Thus, it is essential for hotel management students </p>
                                <Link className="borderButton" to='/french-language'>Read more</Link>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='languageImage'>
                                <motion.img
                                    whileHover={{ scale: 1.1, }}
                                    whileTap={{ scale: 1 }}
                                    initial={{ scale: 1 }}
                                    className="img-fluid"
                                    src='/images/lang1.jpg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                      
            {/* <div className='designEle2'>
                <svg width="416" height="200" viewBox="0 0 416 251" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M415.149 7.55664C373.669 3.8937 271.636 15.3749 195.351 90.6035C99.9954 184.639 71.2606 241.854 -38.0003 244.49" stroke="url(#paint0_linear_58_2301)" strokeOpacity="0.8" strokeWidth="13" />
                    <defs>
                        <linearGradient id="paint0_linear_58_2301" x1="415.149" y1="7.55664" x2="-99.295" y2="75.2983" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#D03D6B" />
                            <stop offset="0.547917" stopColor="#B0519A" />
                            <stop offset="1" stopColor="#5C65AE" />
                        </linearGradient>
                    </defs>
                </svg>
            </div> */}
            {/* <div className='ourLanguages2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='languageImage'>
                                <motion.img
                                    whileHover={{ scale: 1.1, }}
                                    whileTap={{ scale: 1 }}
                                    initial={{ scale: 1 }}
                                    className="img-fluid"
                                    src='/images/lang2.jpg' />
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='languageText'>
                                <h4>Advantages of learning French :</h4>
                                <ul>
                                    <li>It helps in better communication with colleagues and the locals when in France.</li>
                                    <li>There are many francophone countries in the world, where French can be used other than France.</li>
                                    <li>Learning a new language is an add-on in the CV, especially for hotel management students.</li>
                                    <li>A language (French) can be learnt at any age, by anyone, for any purpose, not just if one </li>
                                </ul>
                                <p>  has to go to France. It is an overall growth in oneself, one must just      </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}
                {/* <div className='ourLanguages2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='languageImage'>
                                <motion.img
                                    whileHover={{ scale: 1.1, }}
                                    whileTap={{ scale: 1 }}
                                    initial={{ scale: 1 }}
                                    className="img-fluid"
                                    src='/images/lang2.jpg' />
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='languageText'>
                                <h4>1 Year Program :</h4>
                                <p> ALZEA INDIA presents an extraordinary opportunity for Hotel Management and Culinary students with our France 1 Year Program. France offers an exceptional environment to propel your career with job placements in Michelin Star restaurants, a monthly stipend, and support from the ‘Ministère de l'Intérieur’. Kickstart your hotel industry career with ALZEA INDIA.     </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}
            {/* <div className='internship'>
                <div className='container'>
                    <h1>Internship</h1>
                </div>
                <div className='internshipList'>
                    <div className='internshipItem'>
                        <a href='/'>
                        <img src='/images/int1.png' alt='int1.png' className='bgImg' />
                        <div className='overlayContent'>
                            <img src='/images/user.png' alt='user' />
                            <p>Internship in France</p>
                        </div>
                        </a>
                        
                    </div>
                    <div className='internshipItem'>
                    <a href='/'>
                        <img src='/images/int2.png' alt='int2.png' className='bgImg' />
                        <div className='overlayContent'>
                            <img src='/images/users.png' alt='user' />
                            <p>Internship in other countries</p>
                        </div>
                        </a>
                    </div>
                </div>
            </div> */}
            
        
            <div className='testimonial'>
                <div className='container'>
                    <h1> READ TRANSFORMATIVE STORIES OF <br/> GROWTH AND ACHIEVEMENT</h1>
                 
                    {
                        testimonials.length !== 0 &&  <SwipeSlider testimonials={testimonials}/>
                    }
                   
                    <div className='ViewMoreBox'>
                    <Link to="/testimonial" className='primaryButton'>View More</Link>
                    </div>
                   
                </div>
                
            </div>
            {/* <VideoTestimonial/> */}
            <div className='pioneersSection'>
                <div className='container'>
                    <div className='pioneersSectionInner'>
                    <div className='row align-center'>
                        <div className='col-md-6 text-center'>
                           <img src='/images/globe.png' alt='globe'/>
                        </div>
                        <div className='col-md-6'>
                            <div className='pioneersSectionContent'>
                                <h1>Your Destiny's Premier Partner <br/> <span style={{color:'#373737'}}>We promise you shall rate us #1</span></h1>
                                <p><span>1200</span><br/> Dreams Fulfilled </p>
                                <p><span>11+ Years </span><br/> Of Experience </p>
                                <p><span>Over 200 </span><br/> Michelin Star & Gastronomic Restaurant Partners </p>
                                
                            </div>
                            
                       </div>
                    </div>
                    </div>
                  
                </div>
            </div>

            {
                latestPosts.length !== 0 && <div className='latestPost'>
                <div className='container'>
                    <h1>‘Internship Insights to French Gastronomy : <br/>Our Blogs have got you covered’</h1>
                    <p className='text-center'>Explore the dynamic world of internships through our captivating blogs, where we unveil the latest trends and possibilities in the industry. Whether you seek answers, motivation, or reasoning to shape your career, our engaging blogs have it all. Dive into the world of cultural exposure and gain valuable insights to empower your professional journey. Learn, Grow, and Stay updated with Destiny Calling.</p>
                    <div className='row'>
                      {
                        latestPosts.map((item, i)=>{
                          return <div className='col-lg-4'>
                            
                          <div className='latestPostItems'>
                              <div className='latestPostItemsThumb'>
                                  <img src={item.thumbnail} alt='li.jpg' />
                              </div>
                              <div className='latestPostItemsContent'>
                                  <h3>{item.title}</h3>
                                  <div className='post_author'>
                                      <p>{item.auther_name}</p>
                                      <p>{ format (parseISO(item.post_date), 'd MMM Y') }</p>
                                  </div>               
                                  <div style={{marginTop:'15px'}}>
                                    <Link to={`blog/${item.slug}`} className='borderButton'>View More</Link>
                                  </div>                  
                                  

                              </div>
                          </div>
                      </div>
                        })
                      }
                      
                    </div>
                    {
                        latestPosts.length >= 6  &&  <div className='ViewMoreBox mt-25'>
                        <Link to="/blog" className='primaryButton'>Discover More</Link>
                        </div>
                    }
                   
                   
                </div>
            </div>
            }
              <div className='career-section' style={{
                 backgroundImage:`url('/images/blogThumb.png')`
              }}>
                <div className='container'>
                    <div className='career-inner'>
                      <h3>Looking to work in a creative space that offers growth and a learning curve?</h3>
                      <Link to="/contact-us" className='borderButton'>Join Our Team</Link>
                    </div>
                </div>
                <div className='careerOverlay'></div>
                
            </div>
             <div className='faq'>
                <div className='container'>
                    <h1>Any Questions you could possibly have, we have tried to answer it all here.</h1>
                    <Accordions data={faqs} />
                    <div className='ViewMoreBox1'>
                    <Link to="/faq" className='primaryButton'>View More</Link>
                    </div>
                </div>
            </div>
         

        </Fragment>
    )
}

export default Home