import React, { Fragment, useState, useEffect, forwardRef } from "react";
import "../assets/css/appointment.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiCalendar } from "react-icons/fi";
import { GetAppointment } from "../helpers";
import { Formik } from 'formik';
import { format } from 'date-fns'
import { BsCheck2Circle } from "react-icons/bs";
import { Link } from "react-router-dom";


const AppointmentBooking = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [selectSlot, setSelectSlot] = useState("");
  const [step, setStep] = useState(0);
  const [completeData, setCompleteData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [isChecked, setIsChecked] = useState("");

  const getSlots = async (date) => {
    setLoading(true)
    const resp = await GetAppointment.getAppointmentSlots(date);
    if (resp && resp.status) {
      setAppointmentSlots(resp.slots);
      setLoading(false)

    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="dateSection">
      <button className="dateBtn" onClick={onClick} ref={ref}>
        <FiCalendar fontSize={20} /> Pick a Date
      </button>
      <div className="pickedDate">{value}</div>
    </div>
  ));

  const handleChangeDate = (date) => {
    setStartDate(date);
    setSelectedDate(format(date, 'yyyy-mm-dd'));
  };

  const handleRadioChange = (e) => {
    const { id } = e.currentTarget;
    setIsChecked(id);
    setSelectSlot(id);
  };

  useEffect(() => {
    getSlots(startDate);
    setSelectedDate(format(startDate, 'yyyy-mm-dd'))
  }, [startDate]);

  const handleSteps = () => {
    if (selectSlot !== "") {
      setStep(1);
    }
  };

  const handleSubmit = async(values, { setSubmitting, resetForm  })=>{
    setLoading(true)
       values.date = selectedDate;
       values.slot = selectSlot;
       let resp = await GetAppointment.createAppointments(values);
       if(resp && resp.status){
        setLoading(false);
        setCompleteData(resp.booking_details);
        setStep(2);
       }
     
  }


  return (
    <Fragment>
      <div className="booking-wrapper">
        <h1>Book Appointment</h1>
        <div className="booking-box">
          <div className="form-progress">
            <ul className="form-progress-steps">
              <li className={`${step === 0 || step === 1 || step === 2 ? 'active' : ''}`}>
                <div className="checkIcon">
                  <img src="/images/check-mark.svg" alt="check"/>
                </div>
                <span>Select Slot</span>
              </li>
              <li className={`${step === 1 || step === 2 ? 'active' : ''}`}>
                <div className="checkIcon">
                  <img src="/images/check-mark.svg" alt="check"/>
                </div>
                <span>User Details</span>
              </li>
              <li className={`${step === 2 ? 'active' : ''}`}>
                <div className="checkIcon">
                  <img src="/images/check-mark.svg" alt="check"/>
                </div>
                <span>Summary</span>
              </li>
            </ul>
          </div>
          <div className="form-box">
            {step === 0 && (
              <div className="form-box-step-1">
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => handleChangeDate(date)}
                  customInput={<ExampleCustomInput />}
                />
                <div className="slot-box">
                  <div className="slot-title">
                    <h3>Available Slots</h3>
                  </div>
                
                  <div className="availSlots">
                    {
                      isLoading ? <p>Slot fetching wait..</p> : <>
                      {appointmentSlots && appointmentSlots.length !== 0 ? (
                      appointmentSlots.map((slot, i) => {
                        return (
                          <div
                            className={`slot-item ${
                              isChecked === slot.slot ? "active" : ""
                            }`}
                            key={slot.id}
                          >
                            <input
                              type="radio"
                              className="custom-radio"
                              name={"slot"}
                              id={slot.slot}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor={slot.slot}>
                              <span>{slot.slot}</span>
                            </label>
                          </div>
                        );
                      })
                    ) : (
                      <p className="noSlot">No Slots Available</p>
                    )}
                      </>
                    }
                    
                  </div>
                </div>
                <div className="actionBtn">
                  <button
                    disabled={selectSlot === ""}
                    className={`${selectSlot === "" ? "disableBtn" : ""}`}
                    onClick={handleSteps}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
            {step === 1 && (
              <div className="form-box-step-2">
                <div className="form-box-2">
                  <Formik
                    initialValues={{ name: "", email: "", phone: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.name) {
                        errors.name = "Name is required";
                      }

                      if (!values.email) {
                        errors.email = "Email is required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      if (!values.phone) {
                        errors.phone = "Phone is required";
                      } else if (!/^[0-9]{10}$/i.test(values.phone)) {
                        errors.phone = "Invalid phone number";
                      }
                     
                      return errors;
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            className="form-control br-0"
                            id="name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                           {errors.name && touched.name && <span className='error-help'>{errors.name}</span>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            className="form-control br-0"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                           {errors.email && touched.email && <span className='error-help'>{errors.email}</span>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Phone</label>
                          <input
                            type="text"
                            className="form-control br-0"
                            id="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                          />
                           {errors.phone && touched.phone && <span className='error-help'>{errors.phone}</span>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="comments">Comment</label>
                          <textarea
                            className="form-control br-0"
                            id="comments"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.comments}
                          ></textarea>
                        </div>
                        <div className="actionBtn">
                          <button
                            type="submit"
                            disabled={Object.keys(errors).length}
                            className={`${
                              Object.keys(errors).length ? "disableBtn" : ""
                            }`}
                            onClick={handleSteps}
                          >
                            {isLoading ? 'please wait..' : 'Submit Details'}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
              {step === 2 && (
              <div className="form-box-step-3">
                <div className="form-box-2">

                  <div className="bookingFinal">
                    <h4><BsCheck2Circle fontSize={30} color="#fff"/> <span>Booking submitted successfully</span> </h4>
                  </div>
                
      <div className="card" style={{marginTop:30}}>
        <div className="card-header">
          Booking Details
        </div>
        <div className="card-body">
        {
                  completeData !== null &&  <div className="overview-box">
                    <table>
                        <tr>
                            <th>Name</th><td>{completeData.name}</td>
                        </tr>
                        <tr>
                            <th>Email</th><td>{completeData.email}</td>
                        </tr>
                        <tr>
                            <th>Phone</th><td>{completeData.phone}</td>
                        </tr>
                        <tr>
                            <th>Comment</th><td>{completeData.comments}</td>
                        </tr>
                        <tr>
                            <th>Date</th><td>{completeData.date}</td>
                        </tr>
                        <tr>
                            <th>Slot</th><td>{completeData.slot}</td>
                        </tr>
                    </table>
              </div>
                 }

                 <div className="backtohome">
                     <Link to={'/'}>Back to home</Link>
                 </div>
        </div>
      </div>
              
                </div>
                
                 
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppointmentBooking;
